@import "~foundation-sites/scss/foundation";

h1 {
  font-size: 2.75rem;
}

h1 small {
  color: #6f6f6f;
  font-size: 60%;
  line-height: 0;
}

table {
  width: 100%;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}